import React from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar, Box, IconButton, Paper, Typography } from '@material-ui/core';
import { PermIdentityOutlined, TrendingUp } from '@material-ui/icons';

import { useUser } from '@/contexts/user-context';
import { useStyles } from './styles';

const UserBlock: React.FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const user = useUser();

  const handleClickOnTrending = () => {
    console.log('Trending clicked');
  };

  return (
    <Box p={3} clone>
      <Paper elevation={0}>
        <Box display="flex" alignItems="center">
          <Avatar
            className={classes.avatar}
            src={user?.user?.avatar ?? undefined}
          >
            {!user?.user?.avatar && <PermIdentityOutlined fontSize="inherit" />}
          </Avatar>
          <Box display="flex" justifyContent="space-between" flexGrow={1}>
            <Box>
              <Typography variant="subtitle1">
                {user?.user?.username}
              </Typography>
              <Typography variant="body2">
                {t(`common.${user.user?.isPremium ? 'preemium' : 'freemium'}`)}
              </Typography>
            </Box>
            <IconButton
              color="primary"
              className={classes.icon}
              onAbort={handleClickOnTrending}
            >
              <TrendingUp htmlColor="inherit" fontSize="inherit" />
            </IconButton>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default UserBlock;
