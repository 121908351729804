import React, { useMemo } from 'react';
import ArrowRightIcon from '../icons/arrow-right';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ArrowRightDownIcon from '../icons/arrow-right-down';
import ArrowRightUpIcon from '../icons/arrow-right-up';
import { UserLearningProgressProgressStatus } from '@/graphql';

interface TendencyChipProps {
  type?: UserLearningProgressProgressStatus;
  isActive?: boolean;
}

const TendencyChip: React.FC<TendencyChipProps> = ({
  children,
  type = UserLearningProgressProgressStatus.Unchanged,
  isActive = false,
}) => {
  const { t } = useTranslation();

  const getColor = useMemo(() => {
    if (isActive) {
      switch (type) {
        case UserLearningProgressProgressStatus.Regress:
          return '#E74C3C';
        case UserLearningProgressProgressStatus.Progress:
          return '#2ECC71';
        case UserLearningProgressProgressStatus.Unchanged:
          return '#FFCE00';
        default:
          return '#C6D5E3';
      }
    }
    return '#C6D5E3';
  }, [type, isActive]);

  const translationKey = () => {
    switch (type) {
      case UserLearningProgressProgressStatus.Regress:
        return 'learning-progress.regression';
      case UserLearningProgressProgressStatus.Progress:
        return 'learning-progress.progress';
      default:
        return 'learning-progress.no-change';
    }
  };

  const chooseIcon = () => {
    switch (type) {
      case UserLearningProgressProgressStatus.Regress:
        return <ArrowRightDownIcon htmlColor="currentColor" />;
      case UserLearningProgressProgressStatus.Progress:
        return <ArrowRightUpIcon htmlColor="currentColor" />;
      default:
        return <ArrowRightIcon htmlColor="currentColor" />;
    }
  };

  return (
    <div
      style={{
        height: 32,
        padding: '0 12px',
        borderRadius: 24,
        border: '1px currentColor solid',
        alignItems: 'center',
        gap: 4,
        display: 'inline-flex',
        color: getColor,
        boxSizing: 'border-box',
      }}
    >
      {!children && (
        <span
          style={{
            height: 24,
            width: 24,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {chooseIcon()}
        </span>
      )}
      <Typography variant="body2" color="inherit">
        {children ? children : t(translationKey())}
      </Typography>
    </div>
  );
};

export default TendencyChip;
