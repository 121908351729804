import React from 'react';
import clsx from 'clsx';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    fontSize: 'inherit',
    verticalAlign: 'middle',
  },
});

const ArrowRightIcon: React.FC<SvgIconProps> = ({
  className,
  htmlColor = '#C6D5E3',
  ...rest
}) => {
  const classes = useStyles();

  return (
    <SvgIcon
      viewBox="0 0 14 9"
      className={clsx(classes.root, className)}
      {...rest}
    >
      <path
        d="M1 3.9C0.668629 3.9 0.4 4.16863 0.4 4.5C0.4 4.83137 0.668629 5.1 1 5.1V3.9ZM13.4243 4.92426C13.6586 4.68995 13.6586 4.31005 13.4243 4.07574L9.60589 0.257359C9.37157 0.0230446 8.99167 0.0230446 8.75736 0.257359C8.52304 0.491674 8.52304 0.871573 8.75736 1.10589L12.1515 4.5L8.75736 7.89411C8.52304 8.12843 8.52304 8.50833 8.75736 8.74264C8.99167 8.97696 9.37157 8.97696 9.60589 8.74264L13.4243 4.92426ZM1 5.1L13 5.1V3.9L1 3.9V5.1Z"
        fill={htmlColor}
      />
    </SvgIcon>
  );
};

export default ArrowRightIcon;
