import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Paper, Typography } from '@material-ui/core';
import { LockOpenOutlined } from '@material-ui/icons';

import {
  useLearningProgressQuery,
  UserLearningProgressProgressStatus,
} from '@/graphql';
import AppButton from '@/components/app-button';
import InfoWithPopover from '@/components/info-with-popover';
import TendencyChip from '@/components/tendency-chip';
import { useStyles } from './styles';
import { ResponsivePie } from '@nivo/pie';

const QuestionsBlock: React.FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { data } = useLearningProgressQuery();

  const handleBoostMyLearning = () => {
    console.log('Boost clicked');
  };
  const dataPie = [
    {
      id: 'incorrect',
      value: data?.userLearningProgress?.percentageOfIncorrectAnswers,
    },
    {
      id: 'correct',
      value: data?.userLearningProgress?.percentageOfCorrectAnswers,
    },
  ];

  return (
    <Box p={3} mt={4} clone>
      <Paper elevation={0}>
        <Box>
          <Box display="flex" alignItems="center">
            <Typography variant="subtitle1">
              {t('learning-progress.questions-title')}
            </Typography>
            <span className={classes.popoverWrap}>
              <InfoWithPopover
                text={t('learning-progress.questions-info-box')}
                isInheritIconFontSize
              />
            </span>
          </Box>
          <Box display="flex" justifyContent="between" mt={3}>
            {/* <TwoColorCircle
                  percentages={[
                    data?.userLearningProgress?.percentageOfCorrectAnswers,
                    data?.userLearningProgress?.percentageOfIncorrectAnswers,
                  ]}
                /> */}
            <Box width={165} height={165}>
              <ResponsivePie
                data={dataPie}
                innerRadius={0.5}
                padAngle={3}
                cornerRadius={8}
                activeOuterRadiusOffset={8}
                enableArcLinkLabels={false}
                arcLinkLabelsSkipAngle={10}
                arcLabelsTextColor="#ffffff"
                arcLinkLabelsThickness={2}
                arcLabelsSkipAngle={10}
                isInteractive={false}
                colors={['#E46962', '#61DF76']}
                arcLabel={(d) => `${Math.round(d.value)}%`}
              />
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="end"
              flexGrow={1}
              gridRowGap={12}
            >
              <Box
                display="flex"
                alignItems="center"
                className={classes.totalBox}
              >
                <LockOpenOutlined color="primary" />
                <Typography variant="subtitle2" color="primary">
                  {t('learning-progress.answer')}:{' '}
                  {data?.userLearningProgress?.answeredQuestionsCount}
                </Typography>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="end"
                flexGrow={1}
                gridRowGap={8}
                mt={2}
              >
                <TendencyChip
                  type={UserLearningProgressProgressStatus.Progress}
                  isActive
                >
                  {data?.userLearningProgress?.correctAnsweredQuestionCount}{' '}
                  {t('common.correct')}
                </TendencyChip>
                <TendencyChip
                  type={UserLearningProgressProgressStatus.Regress}
                  isActive
                >
                  {data?.userLearningProgress?.incorrectAnsweredQuestionCount}{' '}
                  {t('common.no-correct')}
                </TendencyChip>
              </Box>
            </Box>
          </Box>
          <Box mt={3.5}>
            <AppButton
              fullWidth
              color="primary"
              variant="contained"
              onClick={handleBoostMyLearning}
            >
              {t('learning-progress.questions-cta')}
            </AppButton>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default QuestionsBlock;
