import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  avatar: {
    fontSize: '1.75rem',
    backgroundColor: theme.palette.background.default,
    height: 60,
    width: 60,
    marginRight: theme.spacing(2),
  },
  icon: {
    width: 24,
    height: 24,
    padding: 4,
    borderRadius: 24,
    background: 'rgba(52, 152, 219, 0.12)!important',
    color: theme.palette.primary.main,
    fontSize: 20,
    '&:hover': {
      background: 'rgba(52, 152, 219, 0.24)!important',
    },
  },
  popoverWrap: {
    marginLeft: 4,
    fontSize: 16,
  },
  totalBox: {
    marginTop: 4,
    padding: '6px 10px',
    background: '#ECFAFF',
    borderRadius: 16,
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 4,
    display: 'inline-flex',
  },
  chartsBox: {
    position: 'relative',
    width: 158,
    height: 158,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  brainBox: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
  },
  loverCaseCaption: {
    fontSize: '12px',
    letterSpacing: '0.4px',
    textTransform: 'none',
    lineHeight: '16px',
    color: '#4A647C',
  },
  coloredLabel: {
    display: 'inline-block',
    width: 14,
    height: 6,
    borderRadius: 10,
  },
  duelStatsItem: {
    display: 'flex',
    alignItems: 'center',
    gap: 6,
  },
  classroomsProgressBox: {
    position: 'relative',
    display: 'inline-flex',
  },
}));
