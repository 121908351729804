import React from 'react';
import clsx from 'clsx';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    fontSize: 'inherit',
    verticalAlign: 'middle',
  },
});

const ArrowRightUpIcon: React.FC<SvgIconProps> = ({
  className,
  htmlColor = '#C6D5E3',
  ...rest
}) => {
  const classes = useStyles();

  return (
    <SvgIcon
      viewBox="0 0 12 10"
      className={clsx(classes.root, className)}
      {...rest}
    >
      <path
        d="M1.01801 8.89706C0.764169 9.11006 0.731059 9.48852 0.94406 9.74236C1.15706 9.99621 1.53551 10.0293 1.78936 9.81632L1.01801 8.89706ZM11.1939 1.69553C11.2228 1.36542 10.9786 1.0744 10.6485 1.04552L5.26906 0.57488C4.93895 0.546 4.64793 0.790194 4.61905 1.1203C4.59017 1.45041 4.83436 1.74143 5.16447 1.77031L9.94621 2.18866L9.52786 6.9704C9.49898 7.30051 9.74318 7.59153 10.0733 7.62041C10.4034 7.64929 10.6944 7.40509 10.7233 7.07498L11.1939 1.69553ZM1.78936 9.81632L10.9819 2.10287L10.2105 1.18361L1.01801 8.89706L1.78936 9.81632Z"
        fill={htmlColor}
      />
    </SvgIcon>
  );
};

export default ArrowRightUpIcon;
