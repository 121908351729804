import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Paper, Typography } from '@material-ui/core';
import { QuestionAnswerOutlined } from '@material-ui/icons';

import {
  useLearningProgressQuery,
  UserLearningProgressProgressStatus,
} from '@/graphql';
import AppButton from '@/components/app-button';
import InfoWithPopover from '@/components/info-with-popover';
import MultiColorCircularProgress from '@/components/multi-color-circular-progress';
import TendencyChip from '@/components/tendency-chip';
import DoctorDisappointedSrc from '@/images/doctor-disappointed.png';
import BrainFill from './brain-fill';
import { useStyles } from './styles';

const ProgressBlock: React.FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const { data } = useLearningProgressQuery();

  const handleClick = () => {
    console.log('analyse journey clicked');
  };

  const handleEmptyClassroomsCta = () => {
    history.push('/biblio');
  };

  return (
    <Box p={3} mt={0.5} clone>
      <Paper elevation={0}>
        <Box>
          <Box display="flex" alignItems="center">
            <Typography variant="subtitle1">
              {t('learning-progress.progress-title')}
            </Typography>
            <span className={classes.popoverWrap}>
              <InfoWithPopover
                text={t('learning-progress.progress-info-box')}
                isInheritIconFontSize
              />
            </span>
          </Box>
          {data?.userLearningProgress?.percentageOfAnswers > 0 ? (
            <>
              <Box display="flex" justifyContent="between" mt={3}>
                <Box className={classes.chartsBox}>
                  <Box className={classes.brainBox}>
                    <MultiColorCircularProgress
                      percentages={[
                        0,
                        data?.userLearningProgress?.percentageOfCorrectAnswers,
                      ]}
                    />
                  </Box>
                  <Box>
                    <BrainFill
                      percent={
                        data?.userLearningProgress?.percentageOfCorrectAnswers
                      }
                    />
                  </Box>
                  <Typography variant="subtitle1" style={{ fontSize: '1.5em' }}>
                    {data?.userLearningProgress?.percentageOfCorrectAnswers}%
                  </Typography>
                  <Typography className={classes.loverCaseCaption}>
                    {t('learning-progress.correct')}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="end"
                  flexGrow={1}
                  gridRowGap={12}
                >
                  {Object.values(UserLearningProgressProgressStatus).map(
                    (type) => (
                      <TendencyChip
                        key={type}
                        type={type}
                        isActive={
                          type === data?.userLearningProgress?.progressStatus
                        }
                      />
                    )
                  )}
                </Box>
              </Box>
              <Box
                display="flex"
                alignItems="end"
                justifyContent="space-between"
                mt={1}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  className={classes.totalBox}
                >
                  <QuestionAnswerOutlined color="primary" />
                  <Typography variant="subtitle2" color="primary">
                    {t('learning-progress.in-total')}:{' '}
                    {data?.userLearningProgress?.totalQuestionCount}
                  </Typography>
                </Box>
              </Box>
              <Box mt={3}>
                <AppButton
                  fullWidth
                  color="primary"
                  variant="contained"
                  onClick={handleClick}
                >
                  {t('learning-progress.progress-cta')}
                </AppButton>
              </Box>
            </>
          ) : (
            <Box mt={3}>
              <Box
                display="flex"
                alignItems="center"
                gridColumnGap={12}
                mb={3}
                p={4}
                borderRadius={8}
                style={{ backgroundColor: '#F6F9FB' }}
              >
                <img
                  src={DoctorDisappointedSrc}
                  width={119}
                  height={102}
                  alt=""
                />
                <Typography variant="body2">
                  {t(`learning-progress.progress-empty.text`)}
                </Typography>
              </Box>
              <AppButton
                fullWidth
                color="primary"
                variant="contained"
                onClick={handleEmptyClassroomsCta}
              >
                {t('learning-progress.classrooms-empty.cta')}
              </AppButton>
            </Box>
          )}
        </Box>
      </Paper>
    </Box>
  );
};

export default ProgressBlock;
