import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Paper, Typography } from '@material-ui/core';

import { useLearningProgressQuery } from '@/graphql';
import AppButton from '@/components/app-button';
import InfoWithPopover from '@/components/info-with-popover';
import MultiColorCircularProgress from '@/components/multi-color-circular-progress';
import ArmFill from './arm-fill';
import EmptyDuel from '../components/empty-duel';
import { useStyles } from './styles';
import { useCreateDuel } from '@/hooks/useCreateDuel';

const DuelsBlock: React.FC = () => {
  const { t } = useTranslation();
  const { handleCreateDuel, creatingLoading } = useCreateDuel({});
  const classes = useStyles();
  const { data } = useLearningProgressQuery();

  const duelsCount = data?.userLearningProgress?.duelsCount || 0;
  const wonDuelsCount = data?.userLearningProgress?.wonDuelsCount || 0;
  const loseDuelsCount = data?.userLearningProgress?.loseDuelsCount || 0;
  const tiedDuelsCount = data?.userLearningProgress?.tiedDuelsCount || 0;

  const wonDuelPercent = (wonDuelsCount * 100) / duelsCount;
  const loseDuelPercent = (loseDuelsCount * 100) / duelsCount;

  const handleClick = () => {
    console.log('go to duels');
  };

  return (
    <Box p={3} mt={4} clone>
      <Paper elevation={0}>
        <Box>
          <Box display="flex" alignItems="center">
            <Typography variant="subtitle1">
              {t(
                `learning-progress.duels-title${duelsCount < 100 ? '2' : ''}`,
                {
                  count: duelsCount,
                }
              )}
            </Typography>
            <span className={classes.popoverWrap}>
              <InfoWithPopover
                text={t('learning-progress.duels-info-box')}
                isInheritIconFontSize
              />
            </span>
          </Box>
          {duelsCount > 0 ? (
            <>
              <Box display="flex" alignItems="center" mt={3}>
                <Box className={classes.chartsBox}>
                  <Box className={classes.brainBox}>
                    <MultiColorCircularProgress
                      percentages={[loseDuelPercent, wonDuelPercent]}
                    />
                  </Box>
                  <Box>
                    <ArmFill percent={wonDuelPercent} />
                  </Box>
                  <Box display="flex" alignItems="center">
                    <Typography
                      variant="subtitle1"
                      style={{ fontSize: '1.75em', marginRight: 4 }}
                    >
                      {wonDuelsCount}
                    </Typography>
                    <Typography variant="caption">/ {duelsCount}</Typography>
                  </Box>
                  <Typography className={classes.loverCaseCaption}>
                    {t('learning-progress.won')}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  gridRowGap={20}
                  ml={6}
                >
                  <Typography
                    variant="caption"
                    color="inherit"
                    className={classes.duelStatsItem}
                  >
                    <span
                      className={classes.coloredLabel}
                      style={{ backgroundColor: '#70E784' }}
                    />
                    {wonDuelsCount} {t('learning-progress.won')}
                  </Typography>
                  <Typography
                    variant="caption"
                    color="inherit"
                    className={classes.duelStatsItem}
                  >
                    <span
                      className={classes.coloredLabel}
                      style={{ backgroundColor: '#45B3EE' }}
                    />
                    {loseDuelsCount} {t('learning-progress.lost')}
                  </Typography>
                  <Typography
                    variant="caption"
                    color="inherit"
                    className={classes.duelStatsItem}
                  >
                    <span
                      className={classes.coloredLabel}
                      style={{ backgroundColor: '#B6E4FA' }}
                    />
                    {tiedDuelsCount} {t('learning-progress.draw')}
                  </Typography>
                </Box>
              </Box>
              <Box mt={3}>
                <AppButton
                  fullWidth
                  color="primary"
                  variant="contained"
                  onClick={handleClick}
                >
                  {t('learning-progress.duels-cta')}
                </AppButton>
              </Box>
            </>
          ) : (
            <Box mx={-2} mb={-5} mt={1}>
              <EmptyDuel
                loading={creatingLoading}
                onCreate={handleCreateDuel}
              />
            </Box>
          )}
        </Box>
      </Paper>
    </Box>
  );
};

export default DuelsBlock;
