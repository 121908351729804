import { gql } from '@apollo/client';
import {
  DAILY_TIPPER_QUESTION_FRAGMENT,
  USER_FRAGMENT,
  CLASSROOM_LS_FRAGMENT,
  USER_QUESTION_FRAGMENT,
  USER_SCORE_FRAGMENT,
  USER_SETTINGS_FRAGMENT,
  LECTURE_FRAGMENT,
  QUIZTIME_LIST_FRAGMENT,
  QUIZTIME__HOMELIST_FRAGMENT,
  QUIZ_PRIZE_ITEM,
} from './fragments';

export const USER_SUBSCRIPTION = gql`
  query userSubscription {
    me {
      id
      isPremium
      isPromotionPremium
      promotionFinishDate
      customer {
        id
        subscription {
          id
          subscriptionType
          status
          upgradeStatus
          currentPeriodEnd
          requestCancel
          lastError
          lastErrorCode
        }
        defaultCard {
          brand
          last4
        }
      }
      isInAppPremium
      inAppSubscription {
        id
        subscriptingType
        expirationDatetime
        status
        isActive
        store
        price
        currency
      }
    }
  }
`;

export const USER_DISCOUNTS = gql`
  query userDiscounts {
    discounts {
      id
      couponName
      status
      subscriptionType
      discount
      startActiveDatetime
      finishActiveDatetime
    }
  }
`;

export const ME_QUERY = gql`
  query userMe {
    me {
      id
      ...user_fragment
      settings {
        ...user_settings_fragment
      }
    }
    monthlyScore {
      id
      points
    }
    contentAvailable
    featureFlags {
      id
      name
      enabled
    }
    joinedClassRooms
  }
  ${USER_FRAGMENT}
  ${USER_SETTINGS_FRAGMENT}
`;

export const DAILY_TIPPER_WEEKLY = gql`
  query dtWeeklyProgress {
    me {
      id
      dailytipper {
        id
        dailyStatus
        totalProgress
        nextAchievement {
          id
          learningDays
        }
        currentAchievement {
          id
          learningDays
        }
        yearAchievement {
          id
          yearNumber
        }
      }
    }
    dailyTipperWeeklyProgress {
      id
      weeklyGoal
      completedDays
      correctAnswersCount
      wrongAnswersCount
      weekNumber
    }
  }
`;

export const ACTIVE_DUELS = gql`
  query getActiveDuels {
    activeDuels {
      id
      opponent {
        id
        avatar
        username
      }
      round {
        id
        roundNumber
        created
        duel {
          id
          status
        }
        userQuestions {
          id
          questionNumber
          user {
            id
          }
          question {
            id
            value
          }
          userAnswer {
            id
            value
          }
        }
      }
      youTurn
      isInitiator
      session {
        id
        classRoom {
          id
          enrollmentType
        }
      }
    }
    startedDuelsToday
  }
`;

export const ONGOING_DUELS = gql`
  query getOngoingDuels {
    activeDuels {
      id
      opponent {
        id
        avatar
        username
      }
      round {
        id
        roundNumber
        created
        duel {
          id
          status
        }
        userQuestions {
          id
          questionNumber
          user {
            id
          }
          userAnswer {
            id
            value
          }
        }
      }
      youTurn
      isInitiator
      session {
        id
        classRoom {
          id
          enrollmentType
        }
      }
    }
    monthlyScore {
      id
      duels
      wins
      loss
      ties
      points
    }
    startedDuelsToday
  }
`;

export const COMPLETED_DUELS = gql`
  query getCompletedDuels {
    completedDuels {
      id
      userDuelsData {
        ...user_score_fragment
        duel {
          id
          updated
          status
        }
      }
      opponentDuelsData {
        ...user_score_fragment
      }
      session {
        id
        classRoom {
          id
          enrollmentType
        }
      }
    }
  }
  ${USER_SCORE_FRAGMENT}
`;

export const DUELS_RANKING = gql`
  query getDuelsRanking {
    ranking {
      id
      user {
        id
        avatar
        username
      }
      points
      rank
    }
    topUsers {
      id
      user {
        id
        avatar
        username
      }
      points
      rank
    }
  }
`;

export const DUELS_QUERY = gql`
  query getDuels {
    activeDuels {
      id
      opponent {
        id
        avatar
        username
      }
      round {
        id
        roundNumber
        created
        duel {
          id
          status
        }
        userQuestions {
          id
          questionNumber
          user {
            id
          }
          userAnswer {
            id
            value
          }
        }
      }
      youTurn
      session {
        id
        classRoom {
          id
          enrollmentType
        }
      }
    }
    startedDuelsToday
    completedDuels {
      id
      userDuelsData {
        ...user_score_fragment
        duel {
          id
          updated
          status
        }
      }
      opponentDuelsData {
        ...user_score_fragment
      }
      session {
        id
        classRoom {
          id
          enrollmentType
        }
      }
    }
    ranking {
      id
      user {
        id
        avatar
        username
      }
      points
      rank
    }
    topUsers {
      id
      user {
        id
        avatar
        username
      }
      points
      rank
    }
    monthlyScore {
      id
      duels
      wins
      loss
      ties
      points
    }
  }
  ${USER_SCORE_FRAGMENT}
`;

export const USER_DUEL_QUERY = gql`
  query userDuel($duelId: UUID!) {
    duelData(duelId: $duelId) {
      id
      status
      opponentScore {
        ...user_score_fragment
      }
      userScore {
        ...user_score_fragment
      }
      rounds {
        id
        created
        roundNumber
        userQuestions {
          ...user_question_fragment
        }
      }
      session {
        id
        classRoom {
          id
          title
        }
      }
      isOnboardingDuel
    }
  }
  ${USER_SCORE_FRAGMENT}
  ${USER_QUESTION_FRAGMENT}
`;

export const NOTIFICATION_SUBSCRIPTION = gql`
  subscription subscribeNotificationCreated {
    notificationCreated {
      id
      updated
      created
      data
      message
      isRead
    }
  }
`;

export const NOTIFICATIONS_QUERY = gql`
  query userNotifications {
    notifications {
      id
      created
      updated
      data
      message
      isRead
    }
  }
`;

export const LAST_NOTIFICATION_QUERY = gql`
  query lastUserNotification {
    lastNotification {
      id
      updated
      created
      data
      message
      isRead
    }
  }
`;

export const USER_NETWORK_CODES_QUERY = gql`
  query userNetworkCodes {
    codes {
      id
      startDate
      finishDate
      sponsor {
        id
        title
        text
        website
        email
        logo
      }
      codeMode
    }
  }
`;

export const USERNAME_VERIFY = gql`
  query usernameVerify($username: String!) {
    usernameVerify(username: $username)
  }
`;

export const EMAIL_VERIFY = gql`
  query emailVerify($email: String!) {
    emailVerify(email: $email)
  }
`;

export const EFN_NUMBER_VERIFY = gql`
  query efnNumberVerify($efnNumber: String!) {
    efnNumberVerify(efnNumber: $efnNumber)
  }
`;

export const ALL_PROFESSIONS = gql`
  query allProfessions {
    professions {
      id
      title
      type {
        id
        type
      }
    }
  }
`;

export const ANOTHER_DATA = gql`
  query anotherData($professionType: Int!) {
    specialitiesByType(professionType: $professionType) {
      id
      title
    }
  }
`;

export const DOCTOR_DATA = gql`
  query doctorData($professionType: Int!) {
    specialitiesByType(professionType: $professionType) {
      id
      title
    }
    workplaces(professionType: $professionType) {
      id
      title
    }
  }
`;

export const MEDICAL_ASSISTANT_DATA = gql`
  query medicalAssistantData($professionType: Int!) {
    professions {
      id
      title
    }
    specialitiesByType(professionType: $professionType) {
      id
      title
    }
    workplaces(professionType: $professionType) {
      id
      title
    }
  }
`;

export const MEDICAL_ASSISTANT_TRAINEE_DATA = gql`
  query medicalAssistantTraineeData($professionType: Int!) {
    workplaces(professionType: $professionType) {
      id
      title
    }
    studyYears {
      id
      title
    }
  }
`;

export const STUDENT_DATA = gql`
  query studentData {
    semesters {
      id
      title
    }
    universities {
      id
      title
    }
    studySemesters {
      id
      title
    }
  }
`;

export const TRAINEE_DATA = gql`
  query traineeData($professionType: Int!) {
    workplaces(professionType: $professionType) {
      id
      title
    }
    educationLevels {
      id
      title
    }
  }
`;

export const TODAY_DAILY_TIPPER = gql`
  query todayDailyTipper {
    dailyTipperData {
      dailyTipperQuestions {
        ...daily_tipper_question_fragment
      }
    }
  }
  ${DAILY_TIPPER_QUESTION_FRAGMENT}
`;

export const DAILY_TIPPER_RESULT = gql`
  query dailyTipperData {
    dailyTipperData {
      dailyResultStatus
      currentAchievement
      daysLost
      dailyTipperQuestions {
        ...daily_tipper_question_fragment
      }
    }
  }
  ${DAILY_TIPPER_QUESTION_FRAGMENT}
`;

export const UPDATE_DT_DAYSLOST = gql`
  query updateDtDayslost {
    dailyTipperData {
      daysLost
    }
  }
`;

export const USER_ACHIEVEMENTS_RANG = gql`
  query userAchievementsRang {
    achievementsRang(showedInRankUsers: 3) {
      achievement {
        id
        learningDays
      }
      usersWithAchievementCount
      usersWithAchievement {
        id
        user {
          avatar
          username
        }
      }
    }
  }
`;

export const CLASS_ROOMS_AVAILABLE = gql`
  query classRoomsAvailable($isNetworkType: Boolean) {
    availableClassRooms(isNetworkType: $isNetworkType) {
      ...classroom_ls_fragment
    }
  }
`;

export const CLASS_ROOMS_ONGOING = gql`
  query classRoomsOngoing {
    ongoingClassRooms {
      isSessionActive
      correctAnswersGoal
      questionsAmount
      correctAnswersAmount
      networkStartDate
      learningSession {
        ...classroom_ls_fragment
        deepLink
      }
      specialityGroupIcon
    }
  }
  ${CLASSROOM_LS_FRAGMENT}
`;

export const CLASS_ROOMS_AVAILABLE_BY_SPECIALITY_GROUP = gql`
  query classRoomsAvailableBySpecialityGroup($specialityGroupId: UUID) {
    availableSessionsBySpecialityGroup(specialityGroupId: $specialityGroupId) {
      ...classroom_ls_fragment
    }
  }
  ${CLASSROOM_LS_FRAGMENT}
`;

export const CLASS_ROOMS_FINISHED = gql`
  query classRoomsFinished {
    finishedClassRooms {
      correctAnswersGoal
      questionsAmount
      correctAnswersAmount
      networkStartDate
      outViaSubscriptionDate
      specialityGroupIcon
      userLearningSessionSettingsId
      learningSession {
        ...classroom_ls_fragment
      }
    }
  }
  ${CLASSROOM_LS_FRAGMENT}
`;

export const FIND_CLASS_ROOMS = gql`
  query findClassRooms($searchPointer: String) {
    findClassRooms(searchPointer: $searchPointer) {
      ...classroom_ls_fragment
      specialityGroupIcon
    }
  }
  ${CLASSROOM_LS_FRAGMENT}
`;

export const FIND_NETWORK_SESSIONS = gql`
  query searchNetworkClassRoomSessions($searchPointer: String) {
    searchNetworkClassRoomSessions(searchPointer: $searchPointer) {
      ...classroom_ls_fragment
      specialityGroupIcon
      isForSingle
    }
  }
  ${CLASSROOM_LS_FRAGMENT}
`;

export const CLASS_ROOM_SESSION = gql`
  query classRoomSession($sessionId: UUID, $userSessionSettingsId: UUID) {
    classRoomLearningSession(sessionId: $sessionId) {
      id
      status
      startDate
      finishDate
      isForPremiumOnly
      duration
      specialityGroupIcon
      deepLink
      maxUserCapacity
      classRoom {
        id
        title
        description
        learningMoreInfoLink
        classRoomImage
        backgroundImage
        enrollmentType
        classRoomLecture {
          id
          lectureNumber
          isVisible
          lectureQuestionCount
          lectureGoal(
            learningSessionId: $sessionId
            userSessionSettingsId: $userSessionSettingsId
          )
          correctAnswerCount(
            learningSessionId: $sessionId
            userSessionSettingsId: $userSessionSettingsId
          )
          lecture {
            id
            title
          }
        }
        aggregatedRate {
          id
          averageRate
          feedbackCount
        }
        certificate {
          id
          points
        }
        organizer {
          id
          name
          email
          logo
          linkToSite
        }
        sponsors {
          id
          title
          logo
        }
      }
    }
    lekSessionsData(learningSessionId: $sessionId) {
      id
      lekSession {
        id
        status
        finishDateTime
        startDateTime
        lek {
          id
          title
          description
          possibleAttempts
        }
      }
      userLekData {
        id
        status
        isPassed
        attemptNumber
      }
    }
  }
`;

export const CLASSROOM_LEK_SESSIONS = gql`
  query classroomLekSessions($sessionId: UUID) {
    lekSessionsData(learningSessionId: $sessionId) {
      id
      lekSession {
        id
        status
        finishDateTime
        startDateTime
      }
    }
  }
`;

export const CR_SESSION_TYPE = gql`
  query CRSessionType($sessionId: UUID) {
    classRoomLearningSession(sessionId: $sessionId) {
      id
      classRoom {
        id
        enrollmentType
      }
    }
  }
`;

export const CLASS_ROOM_DETAILS = gql`
  query classRoomDetails($sessionId: UUID) {
    classRoomLearningSession(sessionId: $sessionId) {
      id
      status
      startDate
      finishDate
      isForPremiumOnly
      isForSingle
      duration
      specialityGroupIcon
      deepLink
      maxUserCapacity
      classRoom {
        id
        title
        description
        learningMoreInfoLink
        classRoomImage
        backgroundImage
        enrollmentType
        aggregatedRate {
          id
          averageRate
          feedbackCount
        }
        certificate {
          id
          points
        }
        isExternalOrganizer
        organizer {
          id
          name
          email
          logo
          linkToSite
        }
        sponsors {
          id
          title
          logo
        }
      }
    }
  }
`;

export const SPECIALITY_GROUP_LIST = gql`
  query specialityGroupList {
    userSpecialityGroupList {
      classRoomSessionCount
      specialityGroup {
        id
        name
        icon
      }
    }
  }
`;

export const SPECIALITY_GROUP = gql`
  query specialityGroup($specialityGroupId: UUID) {
    specialityGroup(specialityGroupId: $specialityGroupId) {
      id
      name
      icon
    }
  }
`;

export const GET_LEK_SESSION = gql`
  query GetLekSession($lekSessionId: UUID, $sessionId: UUID) {
    lekSession(lekSessionId: $lekSessionId) {
      id
      status
      startDateTime
      finishDateTime
      lek {
        id
        title
        lekGoal
        questionCount
        answerTimeDuration
        questions {
          id
          questionImage
          value
          answers {
            id
            value
          }
        }
      }
    }
    userLekData(learningSessionId: $sessionId) {
      id
      created
      status
      elapsedTime
      userLekQuestionAnswerData {
        id
        question {
          id
        }
        userAnswer {
          id
        }
      }
    }
  }
`;

export const GET_LEK_SESSION_RESULTS = gql`
  query GetLekSessionResults($sessionId: UUID) {
    lekSessionsData(learningSessionId: $sessionId) {
      id
      lekSession {
        id
        finishDateTime
        status
        lek {
          id
          title
          lekGoal
          questionCount
          possibleAttempts
        }
        classRoomLearningSession {
          id
          status
        }
      }
      userLekData {
        id
        updated
        status
        correctAnswerCount
        elapsedTime
        isPassed
        attemptNumber
      }
    }
  }
`;

export const CLASS_ROOM_SESSION_PROGRESS = gql`
  query classRoomSessionProgress($sessionId: UUID) {
    classRoomLearningSession(sessionId: $sessionId) {
      id
      classRoom {
        id
        classRoomLecture {
          id
          correctAnswerCount(learningSessionId: $sessionId)
        }
      }
    }
    ongoingClassRooms {
      correctAnswersAmount
      learningSession {
        id
      }
    }
  }
`;

export const CLASS_ROOM_STATISTICS = gql`
  query classRoomStatistics($sessionId: UUID, $userSessionSettingsId: UUID) {
    userQuestionsCountData(
      sessionId: $sessionId
      userSessionSettingsId: $userSessionSettingsId
    ) {
      questionCountTotal
      questionCount
      correctAnswerCount
      wrongAnswerCount
      questionCountRepeat
      correctAnswerCountRepeat
      generalQuestionCount
    }
  }
`;

export const ORGANIZER = gql`
  query organizer($organizerId: UUID) {
    organizer(organizerId: $organizerId) {
      id
      name
      email
      about
      logo
      linkToSite
      city {
        id
        title
      }
      classroomSet {
        id
      }
    }
  }
`;

export const ORGANIZER_CLASSROOMS = gql`
  query organizerClassrooms($organizerId: UUID) {
    organizer(organizerId: $organizerId) {
      id
      name
      classroomSet {
        id
        title
        backgroundImage
        classRoomImage
        enrollmentType
        classRoomLearningSession {
          id
          startDate
          finishDate
          specialityGroupIcon
        }
        aggregatedRate {
          id
          averageRate
          feedbackCount
        }
        certificate {
          id
          points
        }
      }
    }
  }
`;

export const CLASS_ROOM_MEMBERS = gql`
  query classroomMembers($sessionId: UUID) {
    classRoomMembers(classRoomSessionId: $sessionId) {
      id
      isAvailableForDuel
      userData {
        id
        user {
          id
          username
          avatar
        }
        userClassRoomLearningSessionSettings {
          isClosed
        }
        classRoomCorrectAnswersCount
      }
    }
    startedDuelsToday
  }
`;

export const CLASS_ROOM_SETTINGS = gql`
  query classRoomSettings(
    $learningSessionId: UUID
    $userSessionSettingId: UUID
  ) {
    userClassRoomSettings(
      learningSessionId: $learningSessionId
      userSessionSettingId: $userSessionSettingId
    ) {
      id
      created
      updated
      isDuelModeEnabled
      isDailyTipperModeEnabled
      isAchieved
      isClosed
      isOut
      classRoomLearningSession {
        id
        status
        classRoom {
          id
          enrollmentType
        }
      }
      specialityGroupIcon
    }
  }
`;

export const LECTOR = gql`
  query lector($lectorId: UUID) {
    lector(lectorId: $lectorId) {
      id
      firstName
      lastName
      email
      about
      photo
      professions {
        id
        title
      }
      specialities {
        id
        title
      }
      aggregatedRate {
        id
        averageRate
        feedbackCount
      }
      city {
        id
        title
      }
      lectureSet {
        id
        title
        description
        classroomSet {
          id
          title
        }
      }
    }
  }
`;
export const LECTURES = gql`
  query classroomLectures($learningSessionId: UUID) {
    classRoomLectures(learningSessionId: $learningSessionId) {
      id
      lectureNumber
      lecture {
        ...lecture_fragment
      }
    }
  }
  ${LECTURE_FRAGMENT}
`;

export const QUIZ_TIME_DETAILS = gql`
  query quizTimeDetails($quizSessionId: UUID) {
    quizTimeSession(quizSessionId: $quizSessionId) {
      id
      status
      startDate
      startDateTime
      usersCount
      quizTimeDuration
      duelDuration
      finishDateTime
      isForPremiumOnly
      isUseCode
      maxUserCapacity
      quizTime {
        id
        quizTimeImage
        title
        description
        participationConditions
        organizer {
          id
          name
          email
          logo
          linkToSite
        }
        sponsor {
          id
          title
          text
          website
          email
          logo
        }
      }
    }
  }
`;

export const USER_QUIZ_SESSION_SETTINGS = gql`
  query getUserQuizSessionSettings($quizSessionId: UUID) {
    userQuizSessionSettings(quizSessionId: $quizSessionId) {
      id
      userStatus
      isQuizSessionAvailableToStart
    }
  }
`;

export const QUIZ_TIME_MEMBERS = gql`
  query quizTimeUsers($quizSessionId: UUID) {
    quizSessionMembers(quizSessionId: $quizSessionId) {
      id
      userData {
        id
        rank
        points
        user {
          id
          username
          avatar
        }
      }
      isAvailableForQuizDuel
    }
  }
`;

export const PRIVACY_POLICY = gql`
  query privacyPolicy {
    privatePolicy {
      id
      text
    }
  }
`;

export const DATA_PROTECTION = gql`
  query dataProtection {
    dataProtection {
      id
      text
    }
  }
`;

export const IMPRINT = gql`
  query imprint {
    impressum {
      id
      text
    }
  }
`;

export const QUIZ_SESSIONS_ONGOING = gql`
  query QuizSessionsOngoing {
    ongoingQuizSessions {
      ...quiztime_list_fragment
    }
  }
  ${QUIZTIME_LIST_FRAGMENT}
`;

export const QUIZ_SESSIONS_ANNOUNCED = gql`
  query QuizSessionsAnnounced {
    announcedQuizSessions {
      ...quiztime_list_fragment
    }
  }
  ${QUIZTIME_LIST_FRAGMENT}
`;

export const QUIZ_SESSIONS_FINISHED = gql`
  query QuizSessionsFinished {
    finishedQuizSessions {
      id
      quizSession {
        ...quiztime_list_fragment
      }
      userRank
    }
  }
  ${QUIZTIME_LIST_FRAGMENT}
`;

export const QUIZ_SESSIONS_FOR_HOME = gql`
  query quizSessionsOnHomePage($startLess24Hours: Boolean) {
    me {
      id
      settings {
        id
        duelMode
        activeQuizTime {
          id
        }
      }
    }
    homeQuizSessions(startLess24Hours: $startLess24Hours) {
      quizSession {
        ...quiztime_homelist_fragment
      }
      userStatus
    }
    inProgressUserQuizSession {
      ...quiztime_homelist_fragment
    }
  }
  ${QUIZTIME__HOMELIST_FRAGMENT}
`;

export const ACTIVE_QUIZ_DUELS = gql`
  query getActiveQuizDuels($quizSessionId: UUID) {
    activeQuizDuels(quizSessionId: $quizSessionId) {
      id
      opponent {
        id
        avatar
        username
      }
      round {
        id
        roundNumber
        created
        duel {
          id
          status
        }
        userQuestions {
          id
          questionNumber
          user {
            id
          }
          question {
            id
            value
          }
          userAnswer {
            id
            value
          }
        }
      }
      isInitiator
      session {
        id
      }
    }
  }
`;

export const QUIZ_SCORE = gql`
  query quizScore($quizSessionId: UUID) {
    quizDuelScore(quizSessionId: $quizSessionId) {
      id
      duels
      points
      rank
      wins
      ties
      loss
    }
  }
`;

export const COMPLETED_QUIZ_DUELS = gql`
  query getCompletedQuizDuels($quizSessionId: UUID) {
    completedQuizDuels(quizSessionId: $quizSessionId) {
      id
      userDuelsData {
        ...user_score_fragment
        duel {
          id
          updated
          status
        }
      }
      opponentDuelsData {
        ...user_score_fragment
      }
      session {
        id
      }
    }
  }
  ${USER_SCORE_FRAGMENT}
`;

export const QUIZ_PRIZES = gql`
  query getQuizSessionPrize($quizSessionId: UUID) {
    quizSessionPrize(quizSessionId: $quizSessionId) {
      placeList {
        ...quiz_prize_item
      }
      participationList {
        ...quiz_prize_item
      }
      lotteryList {
        ...quiz_prize_item
      }
    }
  }
  ${QUIZ_PRIZE_ITEM}
`;

export const GET_FEEDBACK_QUESTION = gql`
  query getFeedbackQuestion($questionId: UUID!) {
    getFeedbackForQuestion(questionId: $questionId) {
      id
      relevanceRate
      interestRate
      comment
    }
  }
`;

export const LEARNING_PROGRESS = gql`
  query learningProgress {
    userLearningProgress {
      percentageOfAnswers
      progressStatus
      totalQuestionCount
      answeredQuestionsCount
      correctAnsweredQuestionCount
      incorrectAnsweredQuestionCount
      percentageOfCorrectAnswers
      percentageOfIncorrectAnswers
      duelsCount
      wonDuelsCount
      tiedDuelsCount
      loseDuelsCount
    }
  }
`;

// Local queries
export const TAKEN_ACHIEVEMENT = gql`
  query takenAchievement {
    dailyStatus @client
  }
`;

export const GLOBAL_DIALOG = gql`
  query globalDialog {
    dialogId @client
  }
`;
