import React from 'react';
import clsx from 'clsx';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    fontSize: 'inherit',
    verticalAlign: 'middle',
  },
});

const ArrowRightDownIcon: React.FC<SvgIconProps> = ({
  className,
  htmlColor = '#C6D5E3',
  ...rest
}) => {
  const classes = useStyles();

  return (
    <SvgIcon
      viewBox="0 0 12 10"
      className={clsx(classes.root, className)}
      {...rest}
    >
      <path
        id="Vector 13"
        d="M1.78948 0.183684C1.53564 -0.0293173 1.15718 0.00379313 0.944182 0.257638C0.731181 0.511483 0.764291 0.889936 1.01814 1.10294L1.78948 0.183684ZM10.6486 8.95448C10.9787 8.9256 11.2229 8.63458 11.1941 8.30447L10.7234 2.92502C10.6945 2.59491 10.4035 2.35071 10.0734 2.37959C9.7433 2.40847 9.4991 2.69949 9.52798 3.0296L9.94633 7.81134L5.1646 8.22969C4.83449 8.25857 4.59029 8.54959 4.61917 8.8797C4.64805 9.20981 4.93907 9.454 5.26918 9.42512L10.6486 8.95448ZM1.01814 1.10294L10.2107 8.81639L10.982 7.89713L1.78948 0.183684L1.01814 1.10294Z
        "
        fill={htmlColor}
      />
    </SvgIcon>
  );
};

export default ArrowRightDownIcon;
