import React from 'react';

interface MultiColorCircularProgressProps {
  percentages: [number, number];
  colors?: [string, string];
  backgroundColor?: string;
  size?: number;
  strokeWidth?: number;
}

const MultiColorCircularProgress: React.FC<MultiColorCircularProgressProps> = ({
  percentages = [0, 0],
  colors = ['#45B3EE', '#70E784'],
  backgroundColor = '#ECFAFF',
  size = 158,
  strokeWidth = 16,
}) => {
  const radius = (size - strokeWidth) / 2;
  const circumference = 2 * Math.PI * radius;

  const calculateStrokeStyle = (percentage: number, offset: number) => ({
    strokeDasharray: `${(percentage / 100) * circumference} ${circumference}`,
    strokeDashoffset: `-${(offset / 100) * circumference}`,
  });

  return (
    <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
      <circle
        cx={size / 2}
        cy={size / 2}
        r={radius}
        fill="none"
        stroke={backgroundColor}
        strokeWidth={strokeWidth}
      />
      <circle
        cx={size / 2}
        cy={size / 2}
        r={radius}
        fill="none"
        stroke={colors[0]}
        strokeWidth={strokeWidth}
        style={calculateStrokeStyle(percentages[0], 0)}
        strokeLinecap="round"
      />
      <circle
        cx={size / 2}
        cy={size / 2}
        r={radius}
        fill="none"
        stroke={colors[1]}
        strokeWidth={strokeWidth}
        style={calculateStrokeStyle(percentages[1], percentages[0])}
        strokeLinecap="round"
      />
    </svg>
  );
};

export default MultiColorCircularProgress;
