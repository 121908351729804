import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, IconButton, Paper } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import NotificationsIcon from '@material-ui/icons/NotificationsNoneOutlined';
import { Skeleton } from '@material-ui/lab';

import MainLayout from '../components/main-layout';
import QuestionsBlock from './questions-block';
import ProgressBlock from './progress-block';
import UserBlock from './user-block';
import DuelsBlock from './duels-block';
import ClassroomsBlock from './classrooms-block';
import { useLearningProgressQuery } from '@/graphql';

const MyLearningProgress: React.FC = () => {
  const { t } = useTranslation();
  const { data, loading } = useLearningProgressQuery();

  return (
    <MainLayout
      title={t('learning-progress.progress-title')}
      toolbar={
        <Box display="flex" alignItems="center" gridColumnGap={8}>
          <IconButton
            color="primary"
            component={RouterLink}
            size="small"
            to={`/`}
          >
            <Search htmlColor="#fff" />
          </IconButton>
          <NotificationsIcon />
        </Box>
      }
    >
      <Box px={2} pt={2} pb={4}>
        <UserBlock />
        {loading && !data ? (
          <Box mt={1} padding={3} clone>
            <Paper elevation={0}>
              {[...Array(15)].map((i, idx) => (
                <Skeleton key={idx} height={30} />
              ))}
            </Paper>
          </Box>
        ) : (
          <>
            <ProgressBlock />
            <QuestionsBlock />
            <ClassroomsBlock />
            <DuelsBlock />
          </>
        )}
      </Box>
    </MainLayout>
  );
};

export default MyLearningProgress;
