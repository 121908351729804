import React, {
  MouseEvent as ReactMouseEvent,
  useState,
  ReactNode,
} from 'react';

import { Box, Popover, Typography } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import { useStyles } from './styles';

type Props = {
  text: ReactNode;
  isInheritIconFontSize?: boolean;
};

const InfoWithPopover: React.FC<Props> = ({ text, isInheritIconFontSize }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLSpanElement | null>(null);

  const handlePopoverOpen = (
    event: ReactMouseEvent<HTMLSpanElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <Box
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        component="span"
        onPointerEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <InfoOutlinedIcon
          fontSize={isInheritIconFontSize ? 'inherit' : 'small'}
          color="disabled"
        />
      </Box>
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography color="textPrimary" variant="caption">
          {text}
        </Typography>
      </Popover>
    </>
  );
};

export default InfoWithPopover;
