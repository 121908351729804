import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, IconButton, Paper, Typography } from '@material-ui/core';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { ChevronRight } from '@material-ui/icons';

import MultiColorCircularProgress from '@/components/multi-color-circular-progress';
import ProgressSrc from '@/images/progress.svg';
import BookmarkSrc from '@/images/bookmark-circled.svg';
import StarSrc from '@/images/star-circled.svg';
import { useStyles } from './styles';
import { TabNavIdsEnum } from '@/type';
import { LS_KEY_TABINDEX } from '@/utils/constants';
import AppButton from '@/components/app-button';

const ClassroomsBlock: React.FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();

  const classroomsJoinedCount = 5;
  const classroomsInProgressCount = 2;
  const classroomsFinishedCount = 10;
  const classroomsAchievedCount = 7;

  const classroomsAchievedPercent =
    classroomsAchievedCount > 0
      ? Math.round((classroomsAchievedCount * 100) / classroomsFinishedCount)
      : 0;

  const handleEmptyClassroomsCta = () => {
    history.push('/biblio');
  };

  return classroomsInProgressCount > 0 ? (
    <Box mt={4}>
      <Box display="flex" alignItems="center">
        <Typography variant="subtitle1">
          {t('learning-progress.classrooms-title')}
        </Typography>
      </Box>
      <Box display="flex" gridColumnGap={8} mt={4}>
        <Box clone p={3} pr={1} flexBasis={1 / 2} flexGrow={1}>
          <Paper elevation={0}>
            <Box>
              <img src={ProgressSrc} alt="" />
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              gridColumnGap={4}
              mt={3}
            >
              <Box display="flex" flexDirection="column" gridRowGap={4}>
                <Typography variant="body2">
                  {t('learning-progress.statuses.in-progress')}
                </Typography>
                <Typography
                  variant="subtitle2"
                  color={classroomsInProgressCount > 0 ? 'primary' : 'error'}
                >
                  {classroomsInProgressCount}/{classroomsJoinedCount}{' '}
                  {t('common.classrooms')}
                </Typography>
              </Box>
              <IconButton
                color="primary"
                component={RouterLink}
                size="medium"
                to={classroomsInProgressCount > 0 ? '/classrooms' : '/biblio'}
                style={{ padding: 0 }}
              >
                <ChevronRight style={{ color: '#5296D5', fontSize: 32 }} />
              </IconButton>
            </Box>
          </Paper>
        </Box>

        <Box clone p={3} pr={1} flexBasis={1 / 2} flexGrow={1}>
          <Paper elevation={0}>
            <Box className={classes.classroomsProgressBox}>
              <Box>
                <MultiColorCircularProgress
                  size={64}
                  strokeWidth={8}
                  percentages={[0, classroomsAchievedPercent]}
                />
              </Box>
              <Box className={classes.brainBox}>
                <Typography variant="subtitle1" style={{}}>
                  {classroomsAchievedPercent}%
                </Typography>
              </Box>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              gridColumnGap={4}
              mt={3}
            >
              <Box display="flex" flexDirection="column" gridRowGap={4}>
                <Typography variant="body2">
                  {t('learning-progress.statuses.achieved')}
                </Typography>
                <Typography
                  variant="subtitle2"
                  color={classroomsAchievedCount > 0 ? 'primary' : 'error'}
                >
                  {classroomsAchievedCount}/{classroomsFinishedCount}{' '}
                  {t('common.classrooms')}
                </Typography>
              </Box>
              <IconButton
                color="primary"
                component={RouterLink}
                size="medium"
                to={classroomsAchievedCount > 0 ? '/classrooms' : '/biblio'}
                onClick={() => {
                  if (classroomsAchievedCount > 0)
                    localStorage.setItem(
                      `${TabNavIdsEnum.CLASSROOMS}${LS_KEY_TABINDEX}`,
                      '1'
                    );
                }}
                style={{ padding: 0 }}
              >
                <ChevronRight style={{ color: '#5296D5', fontSize: 32 }} />
              </IconButton>
            </Box>
          </Paper>
        </Box>
      </Box>
    </Box>
  ) : (
    <Box mt={4}>
      <Box display="flex" alignItems="center">
        <Typography variant="subtitle1">
          {t('learning-progress.classrooms-empty.title')}
        </Typography>
      </Box>
      <Box display="flex" gridColumnGap={8} mt={4} mb={3}>
        {[
          { key: 'in-progress', imgSrc: BookmarkSrc },
          { key: 'achieved', imgSrc: StarSrc },
        ].map((it) => (
          <Box key={it.key} clone p={3} pr={1} flexBasis={1 / 2} flexGrow={1}>
            <Paper elevation={0}>
              <Box display="flex" flexDirection="column" gridRowGap={12}>
                <img src={it.imgSrc} width={40} height={40} alt="" />
                <Typography variant="body2">
                  {t(`learning-progress.classrooms-empty.text-${it.key}`)}
                </Typography>
              </Box>
            </Paper>
          </Box>
        ))}
      </Box>
      <AppButton
        fullWidth
        color="primary"
        variant="contained"
        onClick={handleEmptyClassroomsCta}
      >
        {t('learning-progress.classrooms-empty.cta')}
      </AppButton>
    </Box>
  );
};

export default ClassroomsBlock;
